import React, { useState } from 'react'
import { axiosbaseurl } from '../../config'
import toast, { Toaster } from 'react-hot-toast'
import { customToastOptions } from '../../Styles/popup_style'
import { Modal } from 'antd'
import CustomLoader from '../CustomLoader'

const WorkModalForm = ({ workModal, setWorkModal, propreneur_id }) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('+91 ')

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState("");

    // validations for first form
    const handleValidation = () => {
        const newErrors = {};

        if (!name) {
            newErrors.name = "Full Name is required!";
        } else if (/\d/.test(name)) {
            newErrors.name = "Digits not allowed in Full Name";
        }

        if (!email) {
            newErrors.email = "Email is required";
        } else if (
            !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
        ) {
            newErrors.email = "Invalid email format";
        }
        if (phone.length === 4)
            newErrors.phone = "Phone number is required";
        else if (phone.length < 14 || phone.length > 14) {
            newErrors.phone = "Invalid phone number";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    // ===========================


    const handleContactForm = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            setLoading(true);
            try {
                const formData = new FormData()
                formData.append("fname", name)
                formData.append("email", email)
                formData.append("phone", phone.slice(4))
                formData.append("propreneur_id", propreneur_id)
                formData.append("type", 'propreneur inquiry')

                const response = await axiosbaseurl.post("/enquiryData", formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                });
                // console.log('response : ', response.data)

                if (response.data.success == true) {
                    toast.success("Data Sent Successfully !!");
                    setName("")
                    setEmail("")
                    setPhone("+91 ")
                    setWorkModal(false)
                }
            } catch (error) {
                console.log("Error : ", error);
                toast.error("Something went wrong!");
            }
            finally {
                setLoading(false);
            }
        }
    };

    const handleChangePhone = (event) => {
        const value = event.target.value;
        // Check if the value starts with '+91' and remove any extra spaces
        if (value.startsWith('+91 ')) {
            setPhone(value);
        } else {
            // Ensure that '+91 ' is always at the beginning
            setPhone('+91 ' + value.replace(/^(\+91\s*)/, ''));
        }
    };

    return (
        <>
            {loading && <CustomLoader />}
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />

            <Modal
                open={workModal}
                title={<h5 className='text-dark-blue fw-600'>Work With Me</h5>}
                width={500}
                centered
                onCancel={() => setWorkModal(false)}
                footer={<div className="footer-none"></div>}
            >
                <div className="mt-md-5">
                    <form onSubmit={handleContactForm}>
                        <div className={`prop-sold-box w-100 py-0 row`}>
                            <section className="col-12 mb-4">
                                <label htmlFor="" className='mb-2'>Full Name <span className='text-danger'>*</span></label>
                                <input type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="form-field career-form-field px-3" />
                                <span className='text-danger badge'>{errors?.name}</span>
                            </section>
                            <section className="col-12 mb-4">
                                <label htmlFor="" className='mb-2'>Email Address <span className='text-danger'>*</span></label>
                                <input type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-field career-form-field px-3" />
                                <span className='text-danger badge'>{errors?.email}</span>
                            </section>
                            <section className="col-12 mb-4">
                                <label htmlFor="" className='mb-2'>Phone Number <span className='text-danger'>*</span></label>
                                <input type="text"
                                    placeholder="Phone No"
                                    value={phone}
                                    onChange={handleChangePhone}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key))
                                            event.preventDefault();
                                    }}
                                    maxLength={14}
                                    className="form-field career-form-field px-3" />
                                <span className='text-danger badge'>{errors?.phone}</span>
                            </section>
                        </div>
                        <div className="text-center mt-4">
                            <button className="bd-connect-button" type='submit'>Let's Connect</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}

export default WorkModalForm
import React from 'react'
import { Link } from 'react-router-dom'
import config from '../../config'

const MarketPopup = ({market_popup}) => {
    return (
        <div className={`market_popup ${!market_popup && 'd-none'}`}>
            <div className="market_box">
                <Link to={`${config.appUrl}propuser-makeover`}>
                    <div><i className='bi bi-cup'></i></div>
                    <p>Prop Cafe</p>
                </Link>
            </div>
            <div className="market_box">
                <Link to={`${config.appUrl}coming-soon`}>
                    <div><i className='bi bi-mortarboard'></i></div>
                    <p>Prop Academy</p>
                </Link>

            </div>
            <div className="market_box">
                <Link to={`${config.appUrl}coming-soon`}>
                    <div><i className='bi bi-wallet2'></i></div>
                    <p>Prop Wallet</p>
                </Link>
            </div>
            <div className="market_box">
                <Link to={`${config.appUrl}coming-soon`}>
                    <div><i className='bi bi-puzzle'></i></div>
                    <p>Prop Samadhan</p>
                </Link>
            </div>
            <div className="market_box">
                <Link to={`${config.appUrl}coming-soon`}>
                    <div><i className='bi bi-bank'></i></div>
                    <p>Prop Makeover</p>
                </Link>
            </div>
            <div className="market_box">
                <Link to={`${config.appUrl}coming-soon`}>
                    <div><i className='bi bi-three-dots'></i></div>
                    <p>More…</p>
                </Link>
            </div>
        </div>
    )
}

export default MarketPopup
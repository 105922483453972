import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"
import config, { axiosbaseurl } from '../../config'
import CustomLoader from '../CustomLoader'

const FindProprenuerDetail = () => {
    const [isShown, setIsShown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sectionsData, setSectionsData] = useState([])
    const [allData, setAllData] = useState([])
    const [page, setPage] = useState(1)
    const [activeFilter, setActiveFilter] = useState('')
    const [headingData, setHeadingData] = useState([])

    const handleClick = () => {
        setIsShown(current => !current);
    };

    const locationData = useLocation()

    const [searchString, setSearchString] = useState(locationData?.state?.searchStr ? locationData?.state?.searchStr : '')

    useEffect(() => {
        const controller = new AbortController();
        async function getProprenuer() {
            try {
                setLoading(true)
                const { data } = await axiosbaseurl.get('/proprenuers');

                if (data.success == true) {
                    setAllData(data.data.data)
                    setHeadingData(
                        {
                            heading: {
                                title: data.data.titles[0]?.title.replaceAll('(', '<span class="fw-bold">')?.replaceAll(')', '</span>'),
                                subtitle: data.data.titles[0]?.subtitle.replaceAll('(', '<span class="font-saira">')?.replaceAll(')', '</span>')
                            },
                            bg_image: data.data.titles[1]?.subtitle
                        }
                    )
                    if (searchString) {
                        const serachData = getSearchedData(data.data.data)
                        setSectionsData(serachData)
                    }
                    else
                        setSectionsData(data.data.data)
                }

                setLoading(false)

            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        getProprenuer()

        return () => {
            setAllData([])
            controller.abort();
        };
    }, [])


    const sortAlphabetically = (sort_type) => {

        setActiveFilter(sort_type)
        let sortedNames;
        if (sort_type === "a-z") {
            sortedNames = [...sectionsData].sort((a, b) => a.fname?.localeCompare(b.fname));
        } else if (sort_type === "z-a") {
            sortedNames = [...sectionsData].sort((a, b) => b.fname?.localeCompare(a.fname));
        }
        setSectionsData(sortedNames);
    };

    const total_cards = 8

    const changePage = (pagecount) => {
        setPage(pagecount)
        window.scrollTo(0, 0)
    }


    const getSearchedData = (searchdata) => {
        return (
            searchdata.filter((prop) => {
                return (
                    prop.fname?.toLowerCase().includes(searchString.toLowerCase()) ||
                    prop.lname?.toLowerCase().includes(searchString.toLowerCase()) ||
                    (prop.fname + " " + prop.lname)?.toLowerCase().includes(searchString.toLowerCase()) ||
                    prop.state_name?.toLowerCase().includes(searchString.toLowerCase()) ||
                    prop.city_name?.toLowerCase().includes(searchString.toLowerCase())
                )
            })
        )
    }

    const onSearchProprenuer = (e) => {
        e.preventDefault()
        setLoading(true)

        setTimeout(() => {
            const serachData = getSearchedData(allData)
            setSectionsData(serachData)
            setLoading(false)
        }, 1000)
    }

    const handleResetData = () => {
        setSectionsData(allData)
        setSearchString('')
    }

    return (
        <div>
            {loading && <CustomLoader />}

            <section className="pt-90">
                <div className="overlay-container">

                    <section className="pt-90 top-cities-banner top-cities-banner-half propreneur-banner"
                         style={{
                            backgroundImage: `linear-gradient(#00000050, #00000050), url("${headingData?.bg_image}")`
                          }}
                    >
                        <div className="d-flex justify-content-center align-items-start text-center h-100">
                            <div>
                                {
                                    headingData &&
                                    <div className='mb-4 propreneur-header-text'>
                                        <h2 className="bd-heading-detail"
                                            dangerouslySetInnerHTML={{ __html: headingData?.heading?.title }}
                                        >
                                        </h2>
                                        <p className="bd-heading-detail"
                                            dangerouslySetInnerHTML={{ __html: headingData?.heading?.subtitle }}
                                        >
                                        </p>
                                    </div>
                                }

                                <div >
                                    <form onSubmit={onSearchProprenuer}>
                                        <div className="main-searh-input-proprenuer">
                                            <div className="bd-search-bar">
                                                <input className="bd-search-input-bar"
                                                    value={searchString}
                                                    onChange={(e) => setSearchString(e.target.value)}
                                                    placeholder="Find a Propreneur" required />
                                            </div>
                                            <div className="bd-button-item">
                                                {/* <Link to={`${config.appUrl}propusers-find-a-propreneur`}> */}
                                                <button className="bd-search-button-bar" type='submit'>
                                                    <i className="fa fa-search search-icons"></i></button>
                                                {/* </Link> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>

            <section className="pt-30">
                <div>
                    <div className="container" style={{ maxWidth: "1300px" }}>
                        {
                            sectionsData?.length > 0 ?
                                <>
                                    <div className="  d-sm-flex align-items-start justify-content-sm-between justify-content-center px-md-0 px-3" style={{ alignItems: "baseline" }}>
                                        <div>
                                            {
                                                allData?.length != sectionsData?.length ?
                                                    <div>
                                                        <div className="particular-row bd-infor-header-section1 d-flex" >
                                                            <div className="pr-10">
                                                                <h3 className="bd-state-name font-saira">{sectionsData[0]?.state_name}</h3>
                                                            </div>
                                                            <div className="pr-10 bd-divider"> | </div>
                                                            <div className="pr-10 bd-city-name">
                                                                <p className='font-saira fs-5'>{sectionsData[0]?.city_name}</p>
                                                            </div>
                                                            {/* <div className="pr-10 bd-divider"> | </div>
                                            <div className="pr-10">
                                                <p className="bd-address-part font-saira">
                                                    {sectionsData[0]?.address}
                                                </p>
                                            </div> */}
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <p className="bd-state-name font-saira">{sectionsData?.length} Propreneur found in {sectionsData[0]?.city_name ? sectionsData[0]?.city_name : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div>
                                                        <h2 className='text-dark-blue font-saira fw-600 mb-md-0 mb-4'>Propreneurs</h2>
                                                    </div>
                                            }
                                        </div>
                                        <div className="bd-infor-header-section2 mb-4 d-flex">
                                            <div>
                                                {
                                                    allData?.length != sectionsData?.length &&
                                                    <button className='blue-btn rounded-3 py-2 px-3 me-2' onClick={handleResetData}>
                                                        <i className='bi bi-arrow-clockwise text-yellow me-md-2'></i>
                                                        <span className='d-md-inline d-none'>Reset</span>
                                                    </button>
                                                }
                                            </div>

                                            <div className="bd-whole-infor-header position-relative" >
                                                <a className="mb-0 dropdown-buttom font-saira" onClick={handleClick}>Sort by Propreneur Name
                                                    <i htmlFor='purpose1' className='fa fa-chevron-down text-yellow pl-50'></i>
                                                </a>
                                                {isShown && (
                                                    <div className="dropdown-content2 dropdown-box bd-content-box1">
                                                        <div className="bd-box-filter-section">
                                                            <div>
                                                                <p className={`bd-dropdown-list font-saira ${activeFilter == 'a-z' && 'active'}`}
                                                                    onClick={() => sortAlphabetically('a-z')}>
                                                                    Propreneur First Name A–Z</p>
                                                                <p
                                                                    className={`mb-0 bd-dropdown-list font-saira ${activeFilter == 'z-a' && 'active'}`}
                                                                    onClick={() => sortAlphabetically('z-a')}
                                                                >Propreneur First Name Z–A</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </> :
                                <div>
                                    <h2 className="text-secondary text-center mb-3">No Propreneur Found </h2>
                                    <div className='d-flex justify-content-center'>
                                        <button className='blue-btn rounded-3 py-2 px-3 me-2' onClick={handleResetData}>
                                            <i className='bi bi-arrow-clockwise text-yellow me-2'></i>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </section>

            <section>
                <div className="container" style={{ maxWidth: "1300px" }}>
                    <div className=''>
                        <div className="row justify-content-md-start justify-content-center ">
                            {sectionsData?.slice(page * total_cards - total_cards, page * total_cards).map((section, index) => (
                                <div key={index} className='col-xl-3 col-lg-4 col-sm-6 col-11'>
                                    <div className=' mb-25 propsection'>
                                        <div>
                                            <Link to={`${config.appUrl}propusers-agent-detail/${section.id}`}>
                                                <div className='propsection-img'>
                                                    <img src={section.prop_avatar} alt="" style={{ objectFit: 'cover' }} />
                                                </div>
                                            </Link>
                                            <div className='main-image'>
                                                <div className='mainimage-container'>
                                                    <div>
                                                        <p className='color-yellow mainimg-name text-capitalize'>{
                                                            section.fname + " " + section.lname}</p>
                                                        <div className='email-phone'>
                                                            <i className='bi bi-envelope color-yellow'></i>
                                                            <p className='color-white'>
                                                                <a href={`mailto:${section.email}`} className='text-white font-saira text-decoration-none'>
                                                                    {section.email}
                                                                </a>
                                                            </p>
                                                        </div>
                                                        <div className='email-phone'>
                                                            <i className='bi bi-telephone color-yellow'></i>
                                                            <p className='color-white'>
                                                                <a href={`tel:${section.contact}`} className='text-white font-saira text-decoration-none'>
                                                                    {section.contact}
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='icon-background position-relative' >
                                                        <i className='bi bi-share-fill fs-5 color-white'></i>
                                                        <div className='social-icons'>
                                                            <div className='social-facebook'>
                                                                <a href={section?.facebook} target='_blank' rel="noreferrer" className='text-decoration-none'>
                                                                    <i className='bi bi-facebook color-white'></i>
                                                                </a>
                                                            </div>
                                                            <div className='social-twitter'>
                                                                <a href={section?.twitter} target='_blank' rel="noreferrer" className='text-decoration-none'>
                                                                    <i className='bi bi-twitter-x fs-6 color-white'></i>
                                                                </a>
                                                            </div>
                                                            <div className='social-instagram'>
                                                                <a href={section?.instagram} target='_blank' rel="noreferrer" className='text-decoration-none'>
                                                                    <i className='bi bi-instagram color-white'></i>
                                                                </a>
                                                            </div>
                                                            <div className='social-linkedin'>
                                                                <a href={section?.linkedn} target='_blank' rel="noreferrer" className='text-decoration-none'>
                                                                    <i className='bi bi-linkedin color-white'></i>
                                                                </a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* {Array.from({ length: 4 }).map((_, rowIndex) => (
                        ))} */}
                    </div>
                    <div className="pb-40 ">
                        <div className="container2">

                            <div className="d-md-flex justify-content-between" style={{ justifyContent: "left" }}>
                                <div className='d-flex justify-content-md-start justify-content-center'>
                                    {
                                        [...Array(Math.ceil(sectionsData.length / total_cards))].map((_, pagecount) => {
                                            pagecount += 1
                                            return (
                                                <div key={pagecount} className={`bd-number-pagination font-saira fw-bold d-flex align-items-center me-2 pe-1 ${page === pagecount && 'fw-bold text-dark'}`} onClick={() => changePage(pagecount)}>

                                                    {pagecount < 10 ? ('0' + pagecount) : pagecount}

                                                    {
                                                        page === pagecount &&
                                                        <div className="bd-number-pagination ms-1" >
                                                            <div className="bd-number-dashed "></div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    sectionsData?.length > 0 &&
                                    <div className="text-md-end text-center mt-md-0 mt-4">
                                        <div>
                                            <p className="bd-text-pagination font-saira">
                                                Showing {(page * total_cards - total_cards) + 1} to {" "}
                                                {(page * total_cards - total_cards) + total_cards} of  {" "}
                                                {sectionsData.length} entries ( filtered from {(sectionsData.length)} total entries)</p>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </div>
    )
}

export default FindProprenuerDetail
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import "../../Styles/BuyPageStyle/BuyStyle.css"
import config, { axiosbaseurl } from '../../config';
import CustomLoader from '../CustomLoader';
import PropertyFilter from './PropertyFilter';
import "./MultiRangeSlider.css"
import MultiRangeSlider from 'multi-range-slider-react';
import SortingSelect from './components/SortingSelect';
import PropertyMapView from './components/PropertyMapView';
import PropertiesData from './PropertiesData';
import secureLocalStorage from 'react-secure-storage';
import toast, { Toaster } from 'react-hot-toast';
import { customToastOptions } from '../../Styles/popup_style';

const PropertyListing = () => {

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [priceFilterPopup, setPriceFilterPopup] = useState(false);

  // sorting items
  const [showItems, setShowItems] = useState(false)
  const [propertyData, setPropertyData] = useState([]);
  const [allPropertyData, setAllPropertyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isListingView, setIsListingView] = useState(false);
  const [page, setPage] = useState(1)
  const [insightData, setInsightData] = useState(false)
  const [liking, setLiking] = useState(false);

  const [nearHomeSale, setNearHomeSale] = useState([])

  // for sort select 
  const [selectedSortItem, setSelectedSortItem] = useState({ title: "Recommeded", value: 1, sortData: null })

  // params
  const params = useParams()

  // for search functionality
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search_type = queryParams.get('type') || false;
  const search_query = queryParams.get('search_query') || false;

  const property_type = params.property_type?.split('-')[2]

  const credentials = secureLocalStorage.getItem('credentials')

  const navigate = useNavigate()

  useEffect(() => window.scrollTo(
    { top: 0, behavior: 'instant' }
  ), [isListingView, search_query])

  // fetching property data
  useEffect(() => {
    const controller = new AbortController();
    async function getPropertyData() {
      try {
        setLoading(true)

        const { data } = await axiosbaseurl.post('/properties', { user_id: credentials ? credentials?.user_id : 0 });
        if (data.success == true) {

          // filtering data accoding property type (sale or rent)
          converAndSetDataAccordingly(data.data)
        }

        setLoading(false)

      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    getPropertyData()
    return () => {
      setPropertyData([])
      setAllPropertyData([])
      controller.abort();
    };
  }, [location.pathname, search_query])

  // creating properties data accordingly
  const converAndSetDataAccordingly = (property_data) => {

    let properties_data = property_data?.map(prop => ({ ...prop, price: property_type === 'rent' ? prop.price_per_month : prop.price }))?.filter(property => property.property_on?.toLowerCase() === property_type)

    properties_data = properties_data?.sort((a, b) => (b.id - a.id))

    if (search_query) {
      // searching property based on user search string
      const matched_data = properties_data?.filter(property => {
        return (
          property?.city_name?.toLowerCase()?.includes(search_query?.toLowerCase()) ||
          property?.locality_name?.toLowerCase()?.includes(search_query?.toLowerCase()) ||
          property?.address?.toLowerCase()?.includes(search_query?.toLowerCase())
          // ||   property?.pincode?.includes(search_query)
        )
      })

      // setting insight data
      if (search_type == 'neighbourhood' && matched_data.length > 0) {

        const { neighbourhood, city_latitude, city_longitude, locality_name } = matched_data[0];
        setInsightData({
          id: neighbourhood,
          name: locality_name
        })

        const temp_data =
          properties_data?.filter(item => (item.latitude && item.longitude))
            ?.map(prop => (
              {
                locality_name: prop?.locality_name,
                city_name: prop?.city_name,
                zipcode: 201301,
                distance: Math.round(haversineDistance(city_latitude, city_longitude, prop?.latitude, prop?.longitude))
              }
            ))

        let unique_near_data = temp_data.reduce((acc, curr) => {
          if (!acc.some(item => item.locality_name === curr.locality_name)) {
            acc.push(curr)
          }
          return acc;
        }, [])

        unique_near_data = unique_near_data.filter(item => item.distance <= 100)
        console.log('unique_near_data', unique_near_data)
        setNearHomeSale(unique_near_data)
      }
      else if (matched_data.length > 0) {
        const { city_name, city } = matched_data[0];
        setInsightData({
          id: city,
          name: city_name
        })
      }

      setPropertyData(matched_data)
      setAllPropertyData(matched_data)
    } else {
      setPropertyData(properties_data)
      setAllPropertyData(properties_data)
    }
  }

  // calculate nearby data
  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;

    const R = 6371; // Radius of the Earth in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in km
  };
  // ===========================

  // Sorting Logic ===============
  const list_items = [
    { title: "Recommeded", value: 1, sortData: () => allPropertyData },
    { title: "Price", value: 2, sortData: sortByLowPrice },
    // { title: "Beds", value: 3, sortData: null },
    { title: "Baths", value: 4, sortData: sortByBaths },
    { title: "Size", value: 5, sortData: sortBySize },
    { title: "Low Price", value: 6, sortData: sortByLowPrice },
    { title: "High Price", value: 7, sortData: sortByHighPrice },
    { title: "Discount Offer", value: 8, sortData: sortByDiscount },
    { title: "Coming Soon", value: 9, sortData: sortByComingSoon }
  ]

  function sortByHighPrice() {
    const sorted_data = allPropertyData.sort((a, b) => (b.price - a.price))
    return sorted_data
  }
  function sortByLowPrice() {
    const sorted_data = allPropertyData.sort((a, b) => (a.price - b.price))
    return sorted_data
  }
  function sortBySize() {
    const sorted_data = allPropertyData.sort((a, b) => (a.built_area - b.built_area))
    return sorted_data
  }
  function sortByBaths() {
    const sorted_data = allPropertyData.sort((a, b) => (Number(a.bathroom) - Number(b.bathroom)))
    return sorted_data
  }
  function sortByDiscount() {
    const sorted_data = allPropertyData.sort((a, b) => (Number(b.discount) - Number(a.discount)))
    return sorted_data
  }
  function sortByComingSoon() {
    const sorted_data = allPropertyData?.filter((prop) => prop?.property_availablity?.includes("Coming Soon"))
    return sorted_data
  }

  // sorting starting
  function startSorting(sortData) {
    setLoading(true)
    setPropertyData(sortData())
    setPage(1)
    window.scrollTo(0, 0)
    setTimeout(() => setLoading(false), 1000)
  }
  // ===== Sorting End ====

  const [minValue, set_minValue] = useState(1000000);
  const [maxValue, set_maxValue] = useState(21000000);
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
    filterPropertyByPriceRange(e.minValue, e.maxValue)
  };

  const min_value_tag = document.querySelector('.price-filter-box .min-caption')
  const max_value_tag = document.querySelector('.price-filter-box .max-caption')

  useEffect(() => {
    if (min_value_tag || max_value_tag) {
      min_value_tag.innerText = Number(min_value_tag.innerText?.replaceAll(',', '')).toLocaleString()
      max_value_tag.innerText = Number(max_value_tag.innerText?.replaceAll(',', '')).toLocaleString()
    }
  }, [minValue, maxValue])


  const changePage = (pagecount) => {
    setPage(pagecount)
    window.scrollTo({ top: 0, behavior: 'instant' })
  }
  const total_cards = 6
  const final_data = propertyData?.slice(page * total_cards - total_cards, page * total_cards)


  // For Price filter
  const allPriceFilterValues = [
    { title: '0', value: 0 },
    { title: '10L', value: 1000000 },
    { title: '20L', value: 2000000 },
    { title: '30L', value: 3000000 },
    { title: '40L', value: 4000000 },
    { title: '50L', value: 5000000 },
    { title: '60L', value: 6000000 },
    { title: '70L', value: 7000000 },
    { title: '80L', value: 8000000 },
    { title: '90L', value: 9000000 },
    { title: '1Cr', value: 10000000 },
    { title: '1.1Cr', value: 11000000 },
    { title: '1.2Cr', value: 12000000 },
    { title: '1.3Cr', value: 13000000 },
    { title: '1.4Cr', value: 14000000 },
    { title: '1.5Cr', value: 15000000 },
    { title: '1.6Cr', value: 16000000 },
    { title: '1.7Cr', value: 17000000 },
    { title: '1.8Cr', value: 18000000 },
    { title: '1.9Cr', value: 19000000 },
    { title: '2Cr', value: 20000000 },
    { title: '2.1Cr', value: 21000000 },
    { title: '2.2Cr', value: 22000000 },
    { title: '2.3Cr', value: 23000000 },
    { title: '2.4Cr', value: 24000000 },
    { title: '2.5Cr', value: 25000000 },
    { title: '2.6Cr', value: 26000000 },
    { title: '2.7Cr', value: 27000000 },
    { title: '2.8Cr', value: 28000000 },
    { title: '2.9Cr', value: 29000000 },
    { title: '3Cr', value: 30000000 }
  ]

  const price_separators = [
    {
      title: '0',
      value: 0
    },
    {
      title: '50L',
      value: 5000000
    },
    {
      title: '1Cr',
      value: 10000000
    },
    {
      title: '1.5Cr',
      value: 15000000
    },
    {
      title: '2Cr',
      value: 20000000
    },
    {
      title: '2.5Cr',
      value: 25000000
    },
    {
      title: '3Cr',
      value: 30000000
    }
  ]

  const startPrice = 0;
  const endPrice = 30000000;

  const minPriceLabel = allPriceFilterValues?.find(item => item.value === minValue).title
  const maxPriceLabel = allPriceFilterValues?.find(item => item.value === maxValue).title

  // ===========================

  // main filter property based on price range
  const filterPropertyByPriceRange = (min_value, max_value) => {
    const filtered_data = allPropertyData?.filter(property => ((property.price >= min_value) && (property.price <= max_value)))

    setPropertyData(filtered_data)
    // setPriceFilterPopup(false)
    setPage(1)
  }

  const handleResetData = () => {
    setPropertyData(allPropertyData)
    setPage(1)
  }


  const handleAddFavourite = async (property_id, islike) => {
    if (credentials) {
      if (!liking) {
        setLiking(true)
        try {
          const formData = {
            user_id: credentials?.user_id,
            property_id,
            islike
          }

          const { data } = await axiosbaseurl.post('/addfavourite', formData)
          // console.log('like : ', data.data)
          converAndSetDataAccordingly(data.data)

        } catch (error) {
          console.log(error)
        } finally {
          setLiking(false)
        }
      }
    } else {
      toast.error('Login Required !')
    }
  }


  const handleReadInsight = () => {
    if (search_type == 'neighbourhood')
      navigate(`${config.appUrl}propuser-locality-detail/${insightData?.id}`)
    else
      navigate(`${config.appUrl}propuser-locality/${insightData?.id}`)
  }

  return (
    <div>
      {
        loading && <CustomLoader />
      }

      <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />

      <section className="pb-50">
        <div className="container-rent">
          <div className="rent-row">
            <div className="bd-rent-section1 position-relative">  {/* Main Flex */}
              <div>
                <div className="bd-buy-navbar">
                  <div className={`bd-section1-row justify-content-between ${!insightData && 'd-none'}`}> {/* First section Flex */}
                    <div className='mb-md-0 mb-3'>
                      <p className="bd-explore-rent mb-0 text-yellow font-saira fw-600 text-capitalize">
                        <span className='font-saira text-dark-blue fw-500'>Explore</span> {insightData?.name} </p>
                      <div className="yellow-line"></div>
                    </div>
                    {
                      insightData &&
                      // <Link to={`${config.appUrl}propuser-locality-detail/${insightData}`}>
                      <button className="bd-btn-more" onClick={handleReadInsight}>Know More Insights</button>
                      // </Link>
                    }
                  </div>

                  {/* min - max - filter */}
                  <div>
                    <div className="bd-section-Price">
                      <div className='d-md-flex align-items-center w-100 py-2'>

                        <div className="bd-sections-text" >
                          <p className="bd-price-rent mb-0 font-saira">Price Range</p>
                        </div>

                        <div className='d-flex align-items-center mt-md-0 mt-4'
                          onClick={() => {
                            setPriceFilterPopup(!priceFilterPopup)
                            setShowItems(false)
                          }}>
                          <div className='custom-designed-select '>
                            <div className='text-center px-3 font-saira'> {minPriceLabel} </div>
                            <button className=" ">
                              <i className='fa fa-chevron-down'></i>
                            </button>
                          </div>

                          <div className='px-2'>-</div>
                          <div className='custom-designed-select '>
                            <div className='text-center px-3 font-saira'> {maxPriceLabel} </div>
                            <button className=" ">
                              <i className='fa fa-chevron-down'></i>
                            </button>
                          </div>
                        </div>

                        <div className='custom-designed-select ms-md-5 mt-md-0 mt-4'
                          onClick={() => { window.scrollTo(0, 0); setIsFilterOpen(!isFilterOpen); }}
                        // onClick={filterPropertyByPriceRange}
                        >
                          <div className='text-center px-3 font-saira'> {isFilterOpen ? 'Closed' : 'Filter'} </div>
                          <button className=" ">
                            <i className='fa fa-chevron-down'></i>
                          </button>
                        </div>

                      </div>
                    </div>

                    {/* double range slide */}
                    <div className='position-relative w-100'>
                      <div className={`price-filter-box ${priceFilterPopup && 'd-block'}`}>
                        <div className='py-4'>
                          <div className='position-relative'>
                            <MultiRangeSlider
                              min={startPrice}
                              max={endPrice}
                              step={1000000}
                              stepOnly={true}
                              minValue={minValue}
                              maxValue={maxValue}
                              onChange={handleInput}
                            />
                            {/* custom price separately */}
                            <div>
                              {
                                price_separators.map((item, index) => {

                                  const left = (item.value / endPrice) * 100
                                  const position = (index + 1) % 2 === 0

                                  return (
                                    <div
                                      key={item.value}
                                      className={`price-separator-${position ? 'top' : 'bottom'}`}
                                      style={{ left: `${left}%` }}
                                    >
                                      <div>{item.title}</div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="bd-section-recommended d-md-flex align-items-center">
                    <div className="bd-content-part">
                      <p className='mb-2 font-saira'> {final_data?.length} of {propertyData?.length} Homes</p>
                    </div>

                    <SortingSelect
                      selectedSortItem={selectedSortItem}
                      setSelectedSortItem={setSelectedSortItem}
                      list_items={list_items}
                      startSorting={startSorting}
                      showItems={showItems}
                      setShowItems={setShowItems}
                    />

                    <div className='d-md-flex d-none justify-content-end mt-md-0 mt-4' style={{ flex: 1 }}>
                      <div className={` view-tab-label ${!isListingView && 'active'} me-3 font-saira`}
                        onClick={() => setIsListingView(false)}>
                        Grid View
                      </div>
                      <div className={` view-tab-label ${isListingView && 'active'} font-saira`}
                        onClick={() => setIsListingView(true)}>
                        Listing View
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${insightData ? 'with_neigh' : 'without_neigh'}`} >
                {
                  isFilterOpen ?
                    <PropertyFilter
                      allPropertyData={allPropertyData}
                      setPropertyData={setPropertyData}
                      setIsFilterOpen={setIsFilterOpen}
                    />
                    :
                    <PropertiesData
                      propertyData={propertyData}
                      final_data={final_data}
                      allPropertyData={allPropertyData}
                      handleResetData={handleResetData}
                      changePage={changePage}
                      isListingView={isListingView}
                      total_cards={total_cards}
                      page={page}
                      isNeighbourSearch={insightData}
                      property_type={property_type}
                      handleAddFavourite={handleAddFavourite}
                      nearHomeSale={nearHomeSale}
                    />
                }

              </div>

              {/* backside side removal */}
              <div className={`bg-dark-overlay ${(priceFilterPopup || showItems) && 'd-block'}`}
                onClick={() => {
                  if (priceFilterPopup)
                    setPriceFilterPopup(false)
                  else
                    setShowItems(false)
                }}></div>

            </div>

            <div className="bd-rent-section1 property_map_box">
              <PropertyMapView
                propertyData={propertyData}
                property_type={property_type}
              />
            </div>

          </div>
        </div>
      </section>



    </div>
  )
}

export default PropertyListing

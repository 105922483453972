import { faBath, faBed } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom';
import config from '../../config';
import { faSquareCaretDown } from '@fortawesome/free-regular-svg-icons';

const PropertyCard = ({ property_data, isListingView, property_type, handleAddFavourite }) => {

    const { discount, property_availablity, image } = property_data

    const imageCount = image?.filter(img => img?.upload_type === 'image')
    const isVirtualTour = image?.some(img => img?.upload_type === '3dvideo')


    function getBadge() {   
        let badge;

        if (property_availablity && property_availablity?.length > 0) {
            badge = <span> {property_availablity?.split(' ')[0]}  <br /> {property_availablity?.split(' ')?.slice(1)?.join(' ')}</span>
        } else if (discount && discount > 0) {
            badge = <span><big className='fs-5 fw-600'>{discount}%</big> <br /> <small>Discount</small></span>
        }

        return badge;
    }

    return (
        <>
            <div className={`col-sm-${isListingView ? '12' : '6'}  mt-4 pt-2`}>
                <div className={`card bd-main-card-size ${!isListingView && 'verticle-prop-card'}`} >
                    {/*  */}

                    <div className={`${isListingView && 'd-flex align-items-center px-3 py-2'}`}>
                        <Link to={`${config.appUrl}home-for-${property_type}/property-details/${property_data?.id}`} className={`${isListingView && 'property_image'}`}>
                            <img className="card-img-top " style={{ borderRadius: '22px' }} src={imageCount[0]?.upload_name} alt="PropertyImage" />

                            <div className='property_card_header'>
                                {/* yellow badge */}
                                {
                                    !isListingView && getBadge() &&
                                    <div className='me-5'>
                                        <div className="yellow-rotate-badge">
                                            {getBadge()}
                                        </div>
                                    </div>
                                }
                                {/* virtual tour */}
                                <div style={{ position: 'relative', zIndex: 1 }}>
                                    {
                                        isVirtualTour &&
                                        <Link to={`${config.appUrl}property-showcase/sale/${property_data?.id}/${'virtual-tour'}`}
                                            className='text-decoration-none text-white font-saira text-uppercase py-1 px-2 rounded fw-500' style={{ fontSize: '11px', backgroundColor: '#143D63' }}>
                                            <i className='bi bi-dpad-fill text-yellow me-2'></i>
                                            virtual tour
                                        </Link>
                                    }
                                </div>
                                {/* image count */}
                                <div className='camera-icon'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        alert('Clicked  !')
                                    }}
                                >
                                    <div>
                                        <i className='bi bi-camera text-yellow' style={{ lineHeight: '0px' }}></i>
                                        <div> 1/{imageCount?.length}  <span className='ms-1'>{'< >'}</span> </div>
                                    </div>
                                </div>
                            </div>

                            <div className='property_card_footer'>
                                <div>
                                    {
                                        (discount > 0 && property_availablity?.length > 0) &&
                                        <span className='text-yellow d-flex flex-column ps-2' style={{ lineHeight: '20px' }}>
                                            <big className='fs-4 fw-600'>{discount}%</big>
                                            <small className='fw-500 text-uppercase' style={{ fontSize: '11px' }}>Discount</small>
                                        </span>
                                    }
                                </div>
                                <div className='favourite-icon'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleAddFavourite(property_data?.id, property_data?.favouriteproperty)
                                    }}
                                ><i className={`bi bi-suit-heart${property_data?.favouriteproperty ? '-fill' : ''}  text-${property_data?.favouriteproperty ? 'yellow' : 'white'}`}></i>
                                </div>
                            </div>
                        </Link>

                        <div className="card-body">
                            <div className="bd-decription-section">
                                <p className="bd-main-rent-content font-saira">₹ {property_data?.price?.toLocaleString()}</p>
                                <p className="card-text bd-main-decription mb-3 font-saira">{property_data?.address}</p>
                                <div className="bd-icon-section-rent border-top py-3">
                                    <div className="bd-three-rent">
                                        <div className='font-saira'>  <FontAwesomeIcon icon={faBed} /><br /> {property_data?.bhk} <span className="middle-line">  </span></div>
                                    </div>
                                    <div className="bd-three-rent " >
                                        <div className='font-saira'> <FontAwesomeIcon icon={faBath} /><br /> {property_data?.bathroom} Bath <span className="middle-line"> </span></div>
                                    </div>
                                    <div className="bd-three-rent">
                                        <div className='font-saira'>
                                            <FontAwesomeIcon
                                                icon={faSquareCaretDown} /><br />
                                            {property_data?.built_area} SqFt
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyCard
import axios from "axios";

export const axiosbaseurl = axios.create({
  baseURL: "https://demo.propusers.com/admin/api/",
  // baseURL: "https://ztpl.net/propuser/crm/api/",
});

const config = {
  appUrl: "/",
  // demo.
};

// In my website all pages and its sections data are getting from database 
// pages like about, career, locations, properties , news, blogs, propreneurs, cities, neighbourhoods, etc many more, all data are coming dynamic from various apis for different pages

// now my requirement is that, i need to add global search functionality in which based on search text all data should be filtered out and show them in list of title, description and like or navigate to its main detailed page

// how can i achieve this in react js

// Answered
// 5057, 4481 

export default config;
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import config, { axiosbaseurl } from '../../config';
import "./search.css"
import { AppGlobalContext } from '../../GlobalContext';
const GlobalSearch = ({ homePageData }) => {

    const [all_website_data, setallWebsiteData] = useState([])
    const [matched_data, setMatched_data] = useState([])

    // getting all data for search
    const { all_website_data: all_row_data } = useContext(AppGlobalContext);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const search_text = queryParams.get('search') || false;

    const navigate = useNavigate()

    // html to text
    const extractText = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        return tempDiv.innerText;
    };

    // creating all data
    useEffect(() => {
        async function startLogic() {

            window.scrollTo({ top: 0, behavior: 'instant' })
            const { about_data, career_data, sell_data, blog_data, news_data,
                propreneur_data, property_data } = all_row_data;

            let all_list_data = [];
            // about us
            const about_sections = ['about', 'our_mission', 'our_vision'];
            about_sections.forEach(element => {
                all_list_data.push({
                    title: about_data[element].subtitle,
                    description: extractText(about_data[element].description),
                    url: "about-us"
                })
            });
            // ========================================================

            // career
            const career_sections = ['intro', 'value', 'empowerment'];
            career_sections.forEach(element => {
                all_list_data.push({
                    title: career_data[element].title,
                    description: extractText(career_data[element].description),
                    url: "career"
                })
            });
            //
            career_data.our_value.divs.forEach(element => {
                all_list_data.push({
                    title: element.title,
                    description: extractText(element.content),
                    url: "career"
                })
            })
            //
            career_data.jobs.job_postings.forEach(element => {
                all_list_data.push({
                    title: element.title,
                    description: extractText(element.company),
                    url: `job-details/${element.slug}`
                })
            })
            // ========================================================

            // blog data
            blog_data.data.forEach(element => {
                all_list_data.push({
                    title: element.post_title,
                    description: extractText(element.post_content),
                    url: `blog/${element.slug}`
                })
            })

            // news data
            news_data.data.forEach(element => {
                all_list_data.push({
                    title: element.post_title,
                    description: extractText(element.post_content),
                    url: `news/${element.slug}`
                })
            })
            // ========================================================

            // propreneur_data
            propreneur_data.data.data.forEach(element => {
                all_list_data.push({
                    title: element.name,
                    description: extractText(element.description),
                    url: `propusers-agent-detail/${element.id}`
                })
            })
            // ========================================================

            // property_data
            property_data.data.forEach(element => {
                all_list_data.push({
                    title: `${element.property_name}, ${element.address}, ${element.property_condition}`,
                    description: extractText(element.property_description),
                    url: `home-for-${element.property_on.toLowerCase()}/property-details/${element.id}`
                })
            })
            // ========================================================

            // cities data
            homePageData.cities.forEach(element => {
                all_list_data.push({
                    title: element.city_name,
                    description: '',
                    url: `propuser-locality/${element.id}`
                })
            })
            // ========================================================

            // locality data
            homePageData.localitities.forEach(element => {
                all_list_data.push({
                    title: element.locality_name,
                    description: extractText(element.description),
                    url: `propuser-locality-detail/${element.id}`
                })
            })
            // ========================================================

            // Propreneur Experience
            const sell_sections = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh'];
            sell_sections.forEach(element => {
                all_list_data.push({
                    title: sell_data?.data[element].title,
                    description: extractText(sell_data?.data[element].subtitle),
                    url: "propreneur-experience"
                })
            });

            // second section
            sell_data?.data?.second?.features?.forEach(element => {
                all_list_data.push({
                    title: element.title,
                    description: extractText(element.subtitle),
                    url: `propreneur-experience`
                })
            })
            // third section
            sell_data?.data?.third?.features?.forEach(element => {
                all_list_data.push({
                    title: element.title,
                    description: extractText(element.subtitle),
                    url: `propreneur-experience`
                })
            })
            //
            // fourth section
            sell_data?.data?.fourth?.features?.forEach(element => {
                all_list_data.push({
                    title: element.title,
                    description: extractText(element.subtitle),
                    url: `propreneur-experience`
                })
            })
            //
            // seventh section
            sell_data?.data?.seventh?.features?.forEach(element => {
                all_list_data.push({
                    title: element.title,
                    description: extractText(element.subtitle),
                    url: `propreneur-experience`
                })
            })
            //
            // console.log(sell_data)
            setallWebsiteData(all_list_data)
        }

        if (homePageData && all_row_data)
            startLogic()
    }, [homePageData, all_row_data, search_text])

    // filtering data based on userInput from above created data
    useEffect(() => {
        if (all_website_data.length) {
            const matched_datas = all_website_data?.filter(item => {
                return (
                    item?.title?.toLowerCase()?.includes(search_text?.toLowerCase()) ||
                    item?.description?.toLowerCase()?.includes(search_text?.toLowerCase())
                )
            })
            setMatched_data(matched_datas)
        }
    }, [all_website_data])

    return (
        <div className='mt-90 py-5'>
            <div className="auto-container-lg">
                <div className='d-md-flex justify-content-between'>
                    <div>
                        <h4>Search results of <strong>"{search_text}"</strong></h4>
                        <div className="yellow-line"></div>
                    </div>

                    <div className='mt-md-0 mt-4'>
                        <button className='bd-search-btn' onClick={() => {
                            navigate(-1)
                        }}>
                            <i className='bi bi-arrow-left me-2'></i> Back
                        </button>
                    </div>
                </div>
                <div>
                    <div className="list mt-4">
                        {matched_data?.map((item, index) => (
                            <div key={index} className="item">
                                <div className="itemTitle text-capitalize"
                                    dangerouslySetInnerHTML={
                                        {
                                            __html: item.title?.toLowerCase().replaceAll(search_text?.toLowerCase(),
                                                `<span class='bg-yellow fw-500'>${search_text}</span>`)
                                        }}
                                />

                                <div className="itemDescription text-capitalize"
                                    dangerouslySetInnerHTML={
                                        {
                                            __html: item.description?.toLowerCase()?.replaceAll(search_text.toLowerCase(),
                                                `<span class='bg-yellow fw-500 px-1'>${search_text}</span>`)
                                        }}
                                />

                                <Link to={`${config.appUrl}${item.url}`} target='_blank' className="itemLink">
                                    Read More
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GlobalSearch
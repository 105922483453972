import React, { useContext, useEffect, useState } from 'react'
import { axiosbaseurl } from '../../../config';
import toast, { Toaster } from 'react-hot-toast';
import CustomLoader from '../../CustomLoader';
import { customToastOptions } from '../../../Styles/popup_style';

const StarterForm = ({ className, pageName }) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('+91 ')
    const [locality, setLocality] = useState('')

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState("");

    const [localitiesOptions, setLocalitiesOptions] = useState([])

    // const { current_location } = useContext(AppGlobalContext);

    useEffect(() => {
        const controller = new AbortController();

        async function getLocalities() {
            try {
                const { data } = await axiosbaseurl.get(`/neighbourhood`);
                if (data.success == true) {
                    setLocalitiesOptions(data.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getLocalities()

        return () => {
            controller.abort();
        };

    }, []);

    // validations for first form
    const handleValidation = () => {
        const newErrors = {};

        if (!name) {
            newErrors.name = "Full Name is required!";
        } else if (/\d/.test(name)) {
            newErrors.name = "Digits not allowed in Full Name";
        }

        if (!email) {
            newErrors.email = "Email is required";
        } else if (
            !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
        ) {
            newErrors.email = "Invalid email format";
        }
        
        if (phone.length === 4) {
            newErrors.phone = "Phone number is required";
        }
        else if (phone.length < 14 || phone.length > 14) {
            newErrors.phone = "Invalid phone number";
        }

        if (!locality) {
            newErrors.location = "Locality is required";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    // ===========================


    const handleContactForm = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            setLoading(true);
            try {
                const formData = new FormData()
                formData.append("fname", name)
                formData.append("email", email)
                formData.append("phone", phone.slice(4))
                formData.append("location", locality)
                // formData.append("ip_location", current_location)
                formData.append("type", pageName)

                const response = await axiosbaseurl.post("/enquiryData", formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                });
                // console.log('response : ', response.data)

                if (response.data.success == true) {
                    toast.success("Data Sent Successfully !!");
                    setName("")
                    setEmail("")
                    setPhone("+91 ")
                    setLocality("")
                }
            } catch (error) {
                console.log("Error : ", error);
                toast.error("Something went wrong!");
            }
            finally {
                setLoading(false);
            }
        }
    };


    const handleChangePhone = (event) => {
        const value = event.target.value;
        // Check if the value starts with '+91' and remove any extra spaces
        if (value.startsWith('+91 ')) {
            setPhone(value);
        } else {
            // Ensure that '+91 ' is always at the beginning
            setPhone('+91 ' + value.replace(/^(\+91\s*)/, ''));
        }
    };

    return (
        <>
            {loading && <CustomLoader />}
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />
            <form onSubmit={handleContactForm} className='auto-container'>
                <div className={`prop-sold-box py-0 row ${className}`}>
                    <section className="col-md-6 mb-4">
                        <input type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onKeyPress={(event) => {
                                if (/[0-9]/.test(event.key))
                                    event.preventDefault();
                            }}
                            className="form-field career-form-field px-3" />
                        <span className='text-danger badge'>{errors?.name}</span>
                    </section>
                    <section className="col-md-6 mb-4">
                        <input type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-field career-form-field px-3" />
                        <span className='text-danger badge'>{errors?.email}</span>
                    </section>
                    <section className="col-md-6 mb-4">
                        <input type="text"
                            placeholder="Phone No"
                            value={phone}
                            onChange={handleChangePhone}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key))
                                    event.preventDefault();
                            }}
                            maxLength={14}
                            className="form-field career-form-field px-3" />
                        <span className='text-danger badge'>{errors?.phone}</span>
                    </section>
                    <section className="col-md-6 mb-4">
                        <select
                            name="location"
                            value={locality}
                            onChange={(e) => setLocality(e.target.value)}
                            className='form-select career-form-field border-0'>
                            <option value={''} > Select Locality </option>
                            {
                                localitiesOptions?.map(locality => {
                                    return (
                                        <option key={locality.id} value={locality.locality_name}> {locality.locality_name} </option>
                                    )
                                })
                            }
                        </select>
                        <span className='text-danger badge'>{errors?.location}</span>
                    </section>
                </div>
                <div className="text-center mt-4">
                    <button className="bd-connect-button" type='submit'>Let's Connect</button>
                </div>
            </form>
        </>
    )
}

export default StarterForm